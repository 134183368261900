.timetableContainer {
  display: flex;
  width: 100%;
}
.timetableSchedule {
  flex: 1;
}
.timetableActions {
  flex: 50px 0;
  display: flex;
  align-items: center;
}
.timetable {
  display: flex;
  height: 50px;
  position: relative;
}

.timeTableSign, .schedules {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
}
.hourSign {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
}
.hourSignLine {
  position: absolute;
  height: 80%;
  border-left: solid 1px #b7b7b7b7;
  margin-left: -50%;
}
.hourSignText {
  position: absolute;
  bottom: -5px;
  font-size: 12px;
  color: #797979;
  margin-left: -50%;
}
