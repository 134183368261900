.datum {
  margin-right: 0.4em;
  margin-bottom: 0.2em;
}
.datum .label {
  line-height: 1;
  margin-bottom: 0.1em;
}
.datum .value {
  padding-left: 0.4em;
  text-align: right;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.3em;
  font-weight: bold;
  line-height: 1;
}
.datum .value .bar {
  box-sizing: border-box;
  padding-right: 10px;
  margin-right: 10px;
  transition: width 300ms 0s ease;
  overflow: hidden;
  min-height: 1em;
  position: relative;
}
.datum .value .ratio {
  right: -60px;
  text-align: left;
  display: block;
}
.number {
  position: absolute;
  text-align: right;
  right: 3px;
}
