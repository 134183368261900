.schedule {
  font-weight: bold;
  font-size: 12px;
  height: auto;
  position: absolute;
  min-width: 6em;
  display: flex;
  justify-content: space-around;
  padding: 6px 4px 6px 8px;
  top: 5px;
  &.kaigi100 {
    background-color: rgba(#A2CEEF, 0.6);
    color: #2185D0;
  }
  &.question_box {
    background-color: rgba(#92E3A5, 0.6);
    color: #21BA45;
  }}
.scheduleEvent {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.scheduleOption {
  position: relative;
}
.scheduleOptionContainer {
  display: none;
  color: #999;
  cursor: pointer;
  padding-right: 6px;
}

.schedule:hover .scheduleOptionContainer {
  display: block;
}
