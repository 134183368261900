.commentContainer {
  display: flex;
  align-items: flex-start;
  border-bottom: solid 1px #777;
  padding: 0.5rem 0;
  font-size: 1rem;
  line-height: 1em;
}

.commentHeader {
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  line-height: 1em;
  margin-bottom: 0.3rem;
}
.commentAuthor {
  font-weight: bold;
  margin-right: 0.25rem;
}
.commentDateTime {
  margin-right: 0.25rem;
}
.commentApprove {
  flex: 35px 0 1;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  &:hover {
    cursor: pointer;
  }
}
.commentContent {
  flex: 1;
  padding-right: 0.5rem;
}
.icon {
  font-size: 20px;
  display: block;
  margin: auto;
}
.checked {
  color: #21ba45;
}
.commentLike {
  font-size: 20px;
  flex: 56px 0;
  align-self: center;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}
.likeIcon {
  margin-right: 4px;
}
.likesCount {
  width: 100%;
}
.liked {
  color: #2185d0;
}

.tag {
  font-size: 0.7rem;
  line-height: 1.2em;
}
