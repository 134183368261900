$keyColor: #0e566c;
$invertKeyColor: white;

.conferencesContainer {
  // flex-direction: column;
  // flex-wrap: wrap;
  display: grid;
  justify-content: center;
  &.item1 {
    grid-template: repeat(1, 1fr) / 1fr;
  }
  &.item2 {
    grid-template: repeat(2, 1fr) / 1fr;
  }
  &.item3 {
    grid-template: repeat(3, 1fr) / 1fr;
  }
  &.item4 {
    grid-template: repeat(4, 1fr) / 1fr;
  }
  &.item5 {
    grid-template: repeat(3, 1fr) / repeat(2, 1fr);
  }
  &.item6 {
    grid-template: repeat(3, 1fr) / repeat(2, 1fr);
  }
  &.item7 {
    grid-template: repeat(4, 1fr) / repeat(2, 1fr);
  }
  &.item8 {
    grid-template: repeat(4, 1fr) / repeat(2, 1fr);
  }
  &.item9 {
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
  }
  &.item10 {
    grid-template: repeat(4, 1fr) / repeat(3, 1fr);
  }
  &.item11 {
    grid-template: repeat(4, 1fr) / repeat(3, 1fr);
  }
  &.item12 {
    grid-template: repeat(4, 1fr) / repeat(3, 1fr);
  }
}
.conferenceContainer {
  width: 100%;
  border: solid 1px #aaa;
  flex: 1;
  display: grid;
  grid-template-rows: 30px 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "header"
    "content";
}
.conferenceHeader {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $keyColor;
  color: $invertKeyColor;
  padding: 0 0.5rem;
  font-size: 0.7rem;
  font-weight: bold;
  &.offline {
    background-color: #aaa;
  }
  .title {
    flex: 1;
    min-width: 0;
  }
  .options {
    margin: 0 10px;
    text-align: right;
    visibility: hidden;
    cursor: pointer;
  }
  .indicator {
    text-align: right;
  }
  .indicator.ANSWERING_QUESTION,
  .indicator.RESULTS_ANNOUNCED_QUESTION {
    animation: slide-top 4.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
    background-color: white;
    padding: 2px 8px;
    border-radius: 4px;
  }
  .indicator.indicator.ANSWERING_QUESTION {
    color: #ea117c;
  }
  .indicator.indicator.RESULTS_ANNOUNCED_QUESTION {
    color: #ff9a13;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover .options {
    visibility: visible;
  }
}
.conferenceContent {
  grid-area: content;
  overflow: scroll;
}

.drawerButtonWrapper {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.chartContainer {
  position: relative;
  height: 100%;
  width: 100%;
}
.chartContent {
  height: 100%;
  width: 100%;
  padding: 10px;
  display: grid;
  grid-template: 30px 1fr / 1fr;
  
}

/* ----------------------------------------------
 * Generated by Animista on 2019-9-16 9:24:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0;
  }
  15% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  60% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
}

.tweetButtonContainer {
  position: absolute;
  top: 10px;
  right: 10px;
}
